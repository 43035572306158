import React from 'react';

export const Logo = () => {
  return (
    <div className="logo">
      <svg xmlns="http://www.w3.org/2000/svg" width="612.707" height="248.049" viewBox="0 0 612.707 248.049">
        <g id="Logo" transform="translate(-411.433 -378.922)">
          <g id="Group_813" data-name="Group 813">
            <path
              id="Path_161"
              data-name="Path 161"
              d="M176.714-105.661H165.458v11.256h11.256Z"
              transform="translate(562.226 530.677)"
              fill="currentColor"
            />
            <path
              id="Path_162"
              data-name="Path 162"
              d="M176.714-74.433H165.458v11.256h11.256Z"
              transform="translate(562.226 559.137)"
              fill="currentColor"
            />
            <path
              id="Path_163"
              data-name="Path 163"
              d="M165.164-90.047H153.908v11.256h11.256Z"
              transform="translate(551.7 544.907)"
              fill="currentColor"
            />
          </g>
          <g id="Group_814" data-name="Group 814">
            <path
              id="Path_159"
              data-name="Path 159"
              d="M24.828,16.956c-4.163,4.656-9.878,7.338-18.768,7.338-14.253,0-21.237-7.126-21.237-16.722,0-9.736,7.055-16.508,21.237-16.508,8.4,0,13.758,2.257,18.133,6.562l6.632-7.479c-6.42-5.715-14.253-8.75-25.048-8.75-20.532,0-33.021,11.078-33.021,26.176S-15.035,33.889,5.778,33.889c11.43,0,19.333-3.175,25.824-9.383ZM89.74-18.605c-20.109,0-32.879,11.29-32.879,26.176S69.63,33.889,89.74,33.889,122.76,22.459,122.76,7.571,109.847-18.605,89.74-18.605m0,9.385c13.617,0,20.815,7.338,20.815,16.791,0,9.243-7.2,16.935-20.815,16.935S68.925,16.814,68.925,7.571c0-9.454,7.2-16.791,20.815-16.791M188.8,17.52l-21.237-34.5H151.477V32.549h11.006V-4.845l22.789,37.394h6.139L214.483-4.845V32.549h11.076V-16.982H209.9Zm105.341,0L272.9-16.982H256.816V32.549h11.008V-4.845l22.789,37.394h6.137L319.822-4.845V32.549H330.9V-16.982H315.236Zm68.014-34.5V32.549h53.623V23.235h-42.4V-16.982ZM483.369,20.06,490,32.549h12.982l-27.023-49.67H460.438l-27.023,49.67h12.63l6.632-12.489ZM468.906-8.022l10.442,20.109H456.7L467.212-8.022Zm94.4,32.315H537.344V10.465H563.31c6.491,0,9.807,3.1,9.807,7.126,0,3.882-3.245,6.7-9.807,6.7m-.847-21.237H537.344V-8.586h25.119c6.843,0,9.524,2.4,9.524,5.715,0,3.033-3.1,5.927-9.524,5.927M569.1,6.585C580.03,6.231,583.981.8,583.981-5.128c0-6.491-5.149-11.854-17.709-11.854H526.126V32.549h41.346c10.3,0,17.992-4.3,17.992-13.546,0-6.562-4.3-12.137-16.369-12.418"
              transform="translate(438.676 593.083)"
              fill="currentColor"
            />
            <path
              id="Path_164"
              data-name="Path 164"
              d="M0,59.8c0,30.61,20.708,44.649,48.615,44.649S97.233,90.409,97.233,59.8V-65.615h-10.8V59.8c0,24.309-16.2,33.846-37.812,33.846S10.8,84.107,10.8,59.8V-65.615H0Z"
              transform="translate(673.658 444.537)"
              fill="currentColor"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export const LogoVertical = () => {
  return (
    <svg className="logo-icon" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 757.97 170.06">
      <g>
        <g fill="currentColor" data-name="Group 813">
          <path
            d="M342.88 322.33h-11.26v11.25h11.26z"
            data-name="Path 161"
            transform="translate(-277.59 -276.23)"
          ></path>
          <path d="M342.88 382h-11.26v11.26h11.26z" data-name="Path 162" transform="translate(-277.59 -276.23)"></path>
          <path
            d="M320.8 352.17h-11.26v11.26h11.26z"
            data-name="Path 163"
            transform="translate(-277.59 -276.23)"
          ></path>
        </g>
        <g data-name="Group 814">
          <path
            fill="currentColor"
            d="M277.59 401.65c0 30.61 20.71 44.65 48.62 44.65s48.62-14 48.62-44.65V276.23H364v125.42c0 24.31-16.2 33.84-37.81 33.84s-37.83-9.54-37.83-33.84V276.23h-10.8z"
            data-name="Path 164"
            transform="translate(-277.59 -276.23)"
          ></path>
        </g>
      </g>
      <path
        fill="currentColor"
        d="M474.93 380.58c-4.16 4.65-9.88 7.34-18.77 7.34-14.25 0-21.24-7.13-21.24-16.73s7.06-16.5 21.24-16.5c8.4 0 13.76 2.25 18.13 6.56l6.64-7.48c-6.42-5.72-14.26-8.75-25.05-8.75-20.53 0-33 11.08-33 26.17s12.21 26.32 33 26.32c11.43 0 19.33-3.17 25.82-9.38zM539.84 345c-20.11 0-32.84 11.31-32.84 26.19s12.77 26.32 32.88 26.32 33-11.43 33-26.32S560 345 539.84 345m0 9.38c13.62 0 20.82 7.34 20.82 16.79 0 9.25-7.2 16.94-20.82 16.94S519 380.44 519 371.19c0-9.45 7.2-16.79 20.81-16.79m99.06 26.74l-21.24-34.5h-16.05v49.53h11v-37.39l22.79 37.39h6.14l23.07-37.39v37.39h11.08v-49.53H660zm105.34 0L723 346.64h-16.08v49.53h11v-37.39l22.79 37.39h6.14l23.07-37.39v37.39H781v-49.53h-15.66zm68-34.5v49.53h53.62v-9.31h-42.4v-40.22zm121.21 37l6.63 12.49h13l-27-49.67h-15.51l-27 49.67h12.63l6.63-12.49zM919 355.6l10.44 20.11H906.8l10.51-20.11zm94.4 32.31h-26v-13.82h26c6.49 0 9.81 3.1 9.81 7.12s-3.25 6.7-9.81 6.7m-.85-21.23h-25.11V355h25.12c6.85 0 9.53 2.4 9.53 5.71 0 3-3.1 5.93-9.53 5.93m6.64 3.53c10.93-.36 14.88-5.79 14.88-11.72 0-6.49-5.15-11.85-17.71-11.85h-40.14v49.53h41.34c10.3 0 18-4.3 18-13.55 0-6.56-4.3-12.13-16.36-12.41"
        data-name="Path 159"
        transform="translate(-277.59 -276.23)"
      ></path>
    </svg>
  );
};
